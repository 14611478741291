import React from 'react';
import ProductBoard from './product/ProductBoard';

const Home = (props) => {
    return (
        <>
            <ProductBoard />
            <div className="header-modal-mark fade"></div>
        </>
    )
};

export default Home;