import React, { useState, useEffect } from 'react'
import {useHistory, Link, useParams} from 'react-router-dom'
import { Switch, Table, Space, Pagination, Tooltip, Skeleton } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Input } from 'reactstrap'
import { OutlinedButton } from '@oneblinktech-org/helios-base'

const DEFAULT_PAGE_SIZE = 20

const CustomTable = (props) => {
    const history = useHistory()
    const [columns, setColumns] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const params = {limit: DEFAULT_PAGE_SIZE};

    let actionColumn = {};
    if(!props.hideActionColumn) {
        actionColumn = {
            title: 'Action',
            key: 'operation',
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Tooltip title="Edit">
                        <Link to={{pathname: props.route + "/edit/" + record.id}} className="mr-1 del-icon"><EditOutlined /></Link>
                    </Tooltip>
                    <Tooltip title="Delete" >
                        <DeleteOutlined onClick={() => props.deleteRecord(record.id)} />
                    </Tooltip>
                </div>
            ),
        }
    }
    useEffect(() => {
        setColumns(
            [
                ...props.columns,
                actionColumn,
            ]
        )
    }, [])

    const onTableChange = (pagination, filters, sorter, extra) => {
    }

    const onPagiChange = (pageNum) => {
        setCurrentPage(pageNum)
        params.pageNum = pageNum - 1;
        props.getData(params)
    }

    const onSwitch = (checked) => {
    }

    const searchData = (e) => {
        params.searchText = e.target.value;
        if (e.target.value?.length >= 3){
            setTimeout(() => {
                props.getData(params)
            }, 1500);
        }
    }

    return (
        <div>
            <div className="d-flex flex-column flex-md-row justify-content-start align-items-center mb-4">
                <div className="input-group gap-3 search col-12 col-xl-7">
                    <div className="input-group-prepend">
                        <span className="input-group-text p-0" id="inputGroupPrepend2">
                            <img src={require('../../assets/images/header/search.svg').default} alt="" />
                        </span>
                    </div>
                    <Input placeholder="Filter list by Name or RO Number" type="text" className="p-0 border-bottom border-secondary-subtle rounded-0" id="header-search" aria-describedby="inputGroupPrepend2" onChange={(event) => searchData(event)} required onKeyDown={(e)=>{if(e.key==='Enter')  props.getData(params) }} />
                </div>
            </div>
            <Table 
                columns={columns}
                dataSource={props.data}
                rowKey={record => record.id}
                align="center" 
                pagination={{position: ['none'], pageSize: props.data.length}} 
                onChange={onTableChange}
                className="pb-2"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            history.push(props.route + "/edit/" + record.id)
                        }
                    };
                }}
                locale={{
                    emptyText: () => props.loading ?
                        <Skeleton active paragraph={{ rows: 15, width: '100%' }} /> : 'No Data'
                }}
            />

            {
                props.data.length > 0 && Math.ceil(props.data.total / DEFAULT_PAGE_SIZE) > 1 &&
                <Space className="custom-table-pos d-flex flex-row justify-content-around py-2">
                    <OutlinedButton
                        title="First"
                        className="uppercase"
                        disabled={currentPage == 1 ? true : false}
                        clickEvent={(e) => onPagiChange(1)}
                    />
                    <Pagination current={currentPage} defaultPageSize={DEFAULT_PAGE_SIZE} total={props.data.total} onChange={onPagiChange} showSizeChanger={false} />
                    <OutlinedButton
                        title="Last"
                        className="uppercase"
                        disabled={currentPage == Math.ceil(props.data.total / DEFAULT_PAGE_SIZE) ? true : false}
                        clickEvent={(e) => onPagiChange( Math.ceil(props.data.total / DEFAULT_PAGE_SIZE) )}
                    />
                </Space>
            }
        </div>
    )
}

export default CustomTable