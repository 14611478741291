import { combineReducers } from 'redux'
import JobReducer from './job/reducer'
import UserReducer from './user/reducer'
import NoteReducer from './note/reducer'

const rootReducer = combineReducers({
    JobReducer,
    UserReducer,
    NoteReducer
});

export default rootReducer