import { takeLatest, put, call } from 'redux-saga/effects'
import { 
    GET_PROFILE,
    UPDATE_PROFILE,
    GET_USER_TYPE,
    UPDATE_USER_IN_ORG,
    ADD_USER_IN_ORG
} from './actionTypes'

import {
    getProfileSuccess,
    getProfileFailed,
    updateProfileSuccess,
    updateProfileFailed,
    getUserTypesSuccess,
    getUserTypesFailed,
    updateUserInOrgSuccess,
    updateUserInOrgFailed,
    addUserInOrgSuccess,
    addUserInOrgFailed
} from "./actions"

import { getProfile, updateProfile, getGetUserTypes, updateUserInOrg, addUserInOrg } from "../../services/user"

function* onGetProfile(data) {

    try {
        const response = yield call(getProfile);
        yield put(getProfileSuccess(response));
    } catch (error) {
        yield put(getProfileFailed(error.response));
    }
}

function* onUpdateProfile(data) {

    try {
        const response = yield call(updateProfile, data.payload);
        yield put(updateProfileSuccess(response.data));
    } catch (error) {
        yield put(updateProfileFailed(error.response));
    }
}

function* onGetUserTypes() {

    try {
        const response = yield call(getGetUserTypes);
        yield put(getUserTypesSuccess(response));
    } catch (error) {
        yield put(getUserTypesFailed(error.response));
    }
}

function* onUpdateUserInOrg(data) {

    try {
        const response = yield call(updateUserInOrg, data.payload);
        yield put(updateUserInOrgSuccess(response));
    } catch (error) {
        yield put(updateUserInOrgFailed(error.response));
    }
}

function* onAddUserInOrg(data) {

    try {
        const response = yield call(addUserInOrg, data.payload);
        yield put(addUserInOrgSuccess(response.data));
    } catch (error) {
        yield put(addUserInOrgFailed(error.response));
    }
}

function* UserSaga() {
    yield takeLatest(GET_PROFILE, onGetProfile)
    yield takeLatest(UPDATE_PROFILE, onUpdateProfile)
    yield takeLatest(GET_USER_TYPE, onGetUserTypes)
    yield takeLatest(UPDATE_USER_IN_ORG, onUpdateUserInOrg)
    yield takeLatest(ADD_USER_IN_ORG, onAddUserInOrg)
}

export default UserSaga;