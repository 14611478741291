import React from 'react'
import { displayShortName } from '../helpers/globals'

function Avatar({ backgroundColor = '$black', userName = '', userImage = '' }) {
    return (
        <div className='assign-user' style={{ backgroundColor: backgroundColor }}>
            {userImage ?
                <img alt="" src={userImage} />
                :
                <span>{displayShortName(userName)}</span>
            }
        </div>
    )
}

export default Avatar