import React from 'react'

const Dashboard = () => {
    return (
        <div className="d-flex flex-column">
            <div className='m-auto'>
                <h6 className="tool-title my-4">Dashboard</h6>
                <div className='d-flex flex-col flex-md-row align-items-center'>
                    <img src={require('../assets/images/dashboard-1.png').default} />
                    <img className='pl-5' src={require('../assets/images/dashboard-2.png').default} />
                </div>
                <div className='d-flex flex-row align-items-center pt-4'>
                    <img src={require('../assets/images/dashboard-3.png').default} />
                    <img className='pl-2' src={require('../assets/images/dashboard-4.png').default} />
                </div>
                <div className='d-flex flex-row align-items-center pt-4'>
                    <img src={require('../assets/images/dashboard-5.png').default} />
                </div>
            </div>
        </div>
    )
}

export default Dashboard