import React, {useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form';
import { connect, useDispatch } from 'react-redux'
import { Switch, DatePicker, Checkbox, Skeleton } from 'antd';
import { FilledButton, FilledBrightButton } from '@oneblinktech-org/helios-base';
import { send_white } from '../../fakeData/svgFiles';
import { Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getNoteList } from '../../store/note/actions';
import { notification } from 'antd';
import { createApiClient } from '@oneblinktech-org/helios-base';
import { getJobByOrg } from '../../store/job/actions';
import { dateFormat } from '../../helpers/stringFormat';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put, remove } = createApiClient({ apiUrl });

const items = [
    {
      key: '1',
      label: `Tab 1`,
      children: `Content of Tab Pane 1`,
    },
    {
      key: '2',
      label: `Tab 2`,
      children: `Content of Tab Pane 2`,
    },
    {
      key: '3',
      label: `Tab 3`,
      children: `Content of Tab Pane 3`,
    },
];

const CUSTOMER = 4;

const AddNodes = (props) => {
    const [formattedDateTime, setFormattedDateTime] = useState('');
    const [loading, setLoading] = useState(true);
    const [isPropertyTrue, setPropertyTrue] = useState(false);
    const [users, setUsers] = useState(props?.backUpData?.users ? props?.backUpData?.users : [])
    const [filteredUsers, setFilteredUsers] = useState(props?.backUpData?.filteredUsers ? props?.backUpData?.filteredUsers : [])
    const [filterType, setFilterType] = useState(props?.backUpData?.filterType ? props?.backUpData?.filterType : "internal")
    const [formData, setFormData] = useState({});
    const [sendtoType, setSendtoType] = useState(props?.backUpData?.sendtoType ? props?.backUpData?.sendtoType : "never")
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, getValues, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            alert: false,
            ...props?.backUpData?.values
        }
    })

    const switchValue = watch('alert', false);

    useEffect(() => {
        // Create a new Date object
        const currentDate = new Date();
    
        // Format the date and time
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[currentDate.getMonth()];
        const day = currentDate.getDate();
        const year = currentDate.getFullYear();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        // Create the formatted date and time string
        const formattedDateTimeString = `${month} ${day}, ${year} - ${hours % 12 || 12}:${minutes} ${ampm}`;
    
        // Set the formatted date and time in the state
        setFormattedDateTime(formattedDateTimeString);
      }, []);

    useEffect(() => {
        const fetchData = async () => {
          const token = localStorage.getItem('h-access_token');
      
          if (props?.backUpData?.type !== 1) {
            try {
                const result = await get('/users?per_page=all&type=note', {
                    "Authorization": `Bearer ` + token
                })
                setUsers(result?.data || [])
                setFilteredUsers(result?.data || [])
                setLoading(false)
            } catch (e) {
                throw e
            }
          }
        };
        
        fetchData();

    }, [props?.backUpData?.type]);

    useEffect(() => {
        var tempData = [];
        if (users.length > 0) {
            if (filterType != 'customer') {
                tempData = users.filter(user => [1, 2, 3].includes(user.type_id));
            } else {
                tempData = users.filter(user =>user.type_id == 4);
            }
        }
        
        if (props?.sharedReply && users.length > 0) {
            tempData = tempData.map(user => ({ ...user, isChecked: user.id == props?.currentNote?.sender?.id ? !user?.isChecked : user?.isChecked }));
            // props?.currentNote?.sender?.type_id == CUSTOMER ? setFilterType("customer") : setFilterType("internal");
        }
        setFilteredUsers(tempData);

    }, [filterType, props?.sharedReply, users]);

    const onChangeDate = (date, dateString) => {
        setValue('alert_expires_at', date)
    };

    const submitNote = (data) => {
        
        let recipient_users = filteredUsers.filter(user => user.isChecked).map(user => user.id)
        
        if (filterType == 'customer') {
            recipient_users = users.filter(user =>user.id == props?.jobItem?.customer_id); 
        }
        
        if (recipient_users.length == 0) return;
        
        setPropertyTrue(true);

        let createData = {
            ...data,
            alert: data.alert == true ? 1 : 0,
            recipient_users: recipient_users,
            job_id: props.jobId,
            sendto_type: sendtoType,
            files: props.files
        }

        const token = localStorage.getItem('h-access_token')
    
        post('/notes', createData, {
            "Authorization": `Bearer ` + token
        }).then(result => {
            setPropertyTrue(false);
            if (result.status == '201') {
                props.setNoteOpen()
                onNotification('Note sent successfully!')
                dispatch(getNoteList(props.jobId));
                props.getJobByOrg()
            } else {
                onNotification('The sending note failed!')
            }
            dispatch({ type: 'REPLY_NOTE', payload: false })
        }).catch(e => {
            setPropertyTrue(false);
            onNotification('The sending note failed!')
            return e
        })
    }

    const onNotification = (description) => {
        notification.open({
            message: 'Alert!',
            description: description,
            onClick: () => {
            },
        });
    }

    const chooseRecipient = (id) => {
        if (filteredUsers.length > 0 ) {
            let tempUsers = filteredUsers.map(user => ({ ...user, isChecked: user.id == id ? !user?.isChecked: user?.isChecked }))
            setFilteredUsers(tempUsers)
        }
    }

    const addMore = () => {
        setFormData(getValues());
        
        props.setNoteOpen({values: getValues(), users: filteredUsers, type: 1, sendtoType: sendtoType, filterType: filterType, filteredUsers: filteredUsers})
    }

    return(
        <form onSubmit={handleSubmit(submitNote)} className='d-flex flex-column pt-3'>
            <div className='d-flex flex-row align-items-center justify-content-between'>
                <div className="sf-pro-bold title">Add New Note</div>
                <span className='date'>{dateFormat(new Date(), 'USER_LOGIN_DATE_FORMAT')}</span>
            </div>
            <div className='d-flex flex-row align-items-center justify-content-between mt-4'>
                <div className="sf-pro-bold title">Send to:</div>
                <div className="radio_container">
                    <input onChange={() => setFilterType("internal")} type="radio" name="filter_type" id="internal" value="internal" checked={filterType == 'internal'}/>
                    <label for="internal">INTERNAL</label>
                    <input onChange={() => setFilterType("customer")} type="radio" name="filter_type" value="customer" id="customer" checked={filterType == 'customer'}/>
                    <label for="customer">TO CUSTOMER</label>
                </div>
            </div>
            <div className={'mt-4 ' + (filterType != 'customer' ? 'note-placeholder' : '')}>
                {
                    (loading && users.length == 0) ?
                    <Skeleton active />
                    :
                    filteredUsers.length == 0 ?
                    <div className='h-full d-flex flex-col justify-content-center align-items-center'>
                        No users found
                    </div>
                    :
                    <table>
                        <tbody>
                            {
                                filteredUsers.map((user, index) => (
                                    // user.id != props.currentUser?.data.id &&
                                    (
                                        user.type_id == CUSTOMER ?
                                            user.id == props?.jobItem?.customer_id &&
                                            <tr currentUserId={props?.currentUser?.id} userId={user.id} className="cursor" onClick={() => chooseRecipient(user.id)} key={index}>
                                                {/* <td disabled={true}>
                                                    <Checkbox
                                                        checked={user?.isChecked}
                                                    >
                                                    </Checkbox>
                                                </td> */}
                                                <td>{user?.firstname} {user?.lastname}</td>
                                                {/*<td><img src={require('../../assets/images/sms.png').default} /></td>*/}
                                            </tr>
                                        :
                                            <tr currentUserId={props?.currentUser?.id} userId={user.id} className="cursor" onClick={() => chooseRecipient(user.id)} key={index}>
                                                <td disabled={true}>
                                                    <Checkbox
                                                        checked={user?.isChecked}
                                                    >
                                                    </Checkbox>
                                                </td>
                                                <td>{user?.firstname} {user?.lastname}</td>
                                                {/*<td><img src={require('../../assets/images/sms.png').default} /></td>*/}
                                            </tr>
                                    )

                                ))
                            }
                        </tbody>
                    </table>
                }
            </div>
            <textarea className='mt-4 p-1' placeholder='Begin typing...' {...register('message', {required: true})}></textarea>
            <div className='mt-3 d-flex justify-content-start align-items-center'>
                {
                    props.files.length > 0 &&
                    props.files.map((item, key) => {
                        return (
                            <>
                                {
                                    item.media_type != 'pdf' ?
                                    <div key={key} className="note_selected_image mr-3">
                                        <div style={{backgroundImage: `url('${item.media_path}')`}} className='image-container'></div>
                                        <div onClick={() => props.removeFile(item.id)} className="remove">x</div>
                                    </div>
                                    :
                                    <div key={key} className="note_selected_image mr-3">
                                        <img src={require('../../assets/images/pdf-icon.png').default} />
                                        <div onClick={() => props.removeFile(item.id)} className="remove">x</div>
                                    </div>
                                }
                            </>
                        )
                    })
                }
                {
                    !props?.sharedReply &&
                    <a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 4.75V1.002a.999.999 0 1 1 2 0V4.75h3.998a.999.999 0 1 1 0 2H7v4.248a.999.999 0 1 1-2 0V6.75H1.002a.999.999 0 1 1 0-2H5Z" fill="#01459D"/></svg>
                        <span onClick={() => addMore()} class="ml-2 note-add_more">Add Files</span>
                    </a>
                }
            </div>
            {/*<div className='mt-4 d-flex align-items-center justify-content-start'>*/}
            {/*    <label className="alert-text my-0 mr-2">Alert This Note</label>*/}
            {/*    <Switch defaultChecked={getValues('alert')} onChange={(value) => setValue('alert', value)} />*/}
            {/*    <Tooltip placement="rightTop" title={<div style={{fontSize: '13px'}}>Choose the type of notification</div>} arrow={true}>*/}
            {/*        <img className='ml-2' src={require('../../assets/images/alert.png').default} />*/}
            {/*    </Tooltip>*/}
            {/*</div>*/}
            {/*{*/}
            {/*    getValues('alert') &&*/}
            {/*    <>*/}
            {/*        <div className='d-flex flex-row align-items-center justify-content-between mt-4'>*/}
            {/*            <label className="alert-text my-0 mr-2">Auto Send Alerts</label>*/}
            {/*            <div className="radio_container auto-alerts">*/}
            {/*                <input onChange={() => setSendtoType("never")} type="radio" name="sendto_type" id="never" value="never" checked={sendtoType == 'never'} />*/}
            {/*                <label for="never">Never</label>*/}
            {/*                <input onChange={() => setSendtoType("weekly")} type="radio" name="sendto_type" value="weekly" id="weekly" checked={sendtoType == 'weekly'}/>*/}
            {/*                <label for="weekly">Weekly</label>*/}
            {/*                <input onChange={() => setSendtoType("daily")} type="radio" name="sendto_type" value="daily" id="daily" checked={sendtoType == 'daily'}/>*/}
            {/*                <label for="daily">Daily</label>*/}
            {/*                <input onChange={() => setSendtoType("hourly")} type="radio" name="sendto_type" value="hourly" id="hourly" checked={sendtoType == 'hourly'}/>*/}
            {/*                <label for="hourly">Hourly</label>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className='mt-4 d-flex align-items-center justify-content-between'>*/}
            {/*            <label className="alert-text my-0 mr-2">Alert Expires</label>*/}
            {/*            <div className='note-date-picker'>*/}
            {/*                <DatePicker defaultValue={getValues('alert_expires_at')} {...register('alert_expires_at', {required: false})} colorBgContainer="#ebf4ff" placeholder="pick date" onChange={onChangeDate} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*}*/}
            <div className='mt-5 d-flex align-items-center justify-content-end'>
                <FilledButton 
                    title="Cancel"
                    clickEvent={(e) => {props.setNoteOpen(); dispatch({ type: 'REPLY_NOTE', payload: false })}}
                />
                <FilledBrightButton
                    disabled={isPropertyTrue}
                    type="submit"
                    svgFile={send_white}
                    title="Send Note"
                    clickEvent={(e) => {}}
                />
            </div>
        </form>
    )
}

const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
);

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    getJobByOrg: () => dispatch(getJobByOrg()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddNodes)