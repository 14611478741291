import React, { useState, useEffect } from 'react'
import {useHistory, Link, useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import CustomTable  from '../../components/tables/customTable'
import Pusher from "pusher-js";
import { useDocumentTitle } from '../../components/useDocumentTitle';

import {
    getJobs,
    deleteJob
} from '../../store/job/actions'
import { dateFormat } from '../../helpers/stringFormat'

const JobList = (props) => {

    useDocumentTitle("Helios - Job List")
    
    const columns = [
        {
            title: 'RO Number',
            fixed: 'left',
            align: 'left',
            sorter: (a, b) => (a.ro_number || "").localeCompare(b.ro_number || ""),
            render: record => <span>{record.ro_number}</span>
        },
        {
            title: 'Customer',
            align: 'left',
            sorter: (a, b) => (a.customer.firstname || "").localeCompare(b.customer.firstname || ""),
            render: record => (
                <a className="table_anchor">
                    {record.customer && record.customer.firstname + ' ' + record.customer.lastname}
                </a>
            ),
        },
        {
            title: 'Vehicle',
            align: 'left',
            render: record => (
                <span>
                    {record.vehicle &&
                        `${record.vehicle?.year} ${record.vehicle?.make?.name} ${record.vehicle.model} ${record.vehicle?.vehicle_color ? record.vehicle?.vehicle_color : ''}`
                    }
                </span>
            ),
        },
        {
            title: 'Status',
            align: 'left',
            sorter: (a, b) => (a.plan_date || "").localeCompare(b.plan_date || ""),
            render: record => (
                <span>
                    {record.status_from_organization == null ? 
                        (record.plan_date == null ? 'Unscheduled' : 'Scheduled') 
                        : record.status_from_organization.name}
                </span>
            ),
        },
        {
            title: 'Assigned To',
            align: 'left',
            sorter: (a, b) => (a.assigned_to_user && a.assigned_to_user.firstname || "").localeCompare(b.assigned_to_user && b.assigned_to_user.firstname || ""),
            render: record => (
                <span>
                    {record.assigned_to_user ? record.assigned_to_user.firstname : ''} {record.assigned_to_user ? record.assigned_to_user.lastname : ''}
                </span>
            ),
        },
        {
            title: 'Last updated',
            dataIndex: 'updated_at',
            align: 'left',
            sorter: (a, b) => (a.updated_at || "").localeCompare(b.updated_at || ""),
            render: date => dateFormat(date, 'TXT_YEAR_DATE')
        }
    ];

    useEffect(() => {
        props.getJobs(0)

        var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        });

        var channel = pusher.subscribe("product-board");
        channel.bind("job-event", (data) => {
            if (data.event == 'card changed') {
                props.getJobs(0)
            }
        });

        var orgChannel = pusher.subscribe("organizations");
        orgChannel.bind("choose-event", (data) => {
            props.getJobs(0)
        });

        return (() => {
			pusher.unsubscribe('organizations')
			pusher.unsubscribe('product-board')
		})
    }, [])

    return (
        <div className="full-height-top">
            <CustomTable
                columns={columns}
                searchBy='customer'
                hideActionColumn={true}
                data={props?.jobs?.data?.sort((a, b) => a.customer.lastname.localeCompare(b.customer.lastname))}
                getData={(params) => props.getJobs(params)}
                deleteRecord={(id) => props.deleteJob(id)}
                route="/job"
                refreshTable={() => props.getJobs(0)}
                loading={props.jobs.loading}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        jobs: state.JobReducer.jobs,
    }
}

const mapDispachToProps = (dispatch) => ({
    getJobs: (offset) => dispatch(getJobs(offset)),
    deleteJob: (id) => dispatch(deleteJob(id)),
})

export default connect(mapStateToProps, mapDispachToProps)(JobList)