import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Divider, Spin, Modal, Skeleton } from 'antd';
import VehicleUploader from '../modals/VehicleUploader';
import { FilledBrightButton, FilledLightButton } from '@oneblinktech-org/helios-base';
import { plus, send_white } from '../../fakeData/svgFiles.js';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import add_photo from "../../assets/images/production_board/add_photo.png"; 
import { lightOrDark } from '../../helpers/globals';
import {
    getJobVehicleImgs
} from '../../store/job/actions';
import { grayColor } from '../../helpers/globals';
import { notification } from 'antd';
import AddNodes from '../modals/AddNodes';
import { createApiClient } from '@oneblinktech-org/helios-base';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment'
import Block from '../common/block.js';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put, remove } = createApiClient({ apiUrl });

const PhotoManagement = (props) => {

    const [uploaderOpen, setUploaderOpen] = useState(false);
    const [imgOpen, setImgOpen] = useState(false);
    const [imgIndex, setImgIndex] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [fileCount, setFileCount] = useState(0);
    const [images, setImages] = useState([])
    const [noteOpen, setNoteOpen] = useState(false);
    const [files, setFiles] = useState(false);
    const [backUpData, setBackUpData] = useState({})
    const [downloadSpin, setDownloadSpin] = useState(false);
    const [isDragging, setDragging]=useState(false)
    const [filesOpen, setFilesOpen] = useState(true)
    const [vehicleImgs, setVehicleImgs] = useState([])
    const dispatch = useDispatch()

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4, // Adjust as needed
        slidesToScroll: 4, // Adjust as needed
        rows: 2, // Two rows
        vertical: false,
        swipeToSlide: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7,
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    rows: 3,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    vertical: false,
                    variableWidth: true,
                }
            },
        ]
    };  
    
    useEffect(() => {
        if (props.item.id != "") {
            props.getJobVehicleImgs(props.item.id);
            setSelectedFiles({});
            props.vehicleImgs.data.images = [];
            setFileCount(0);
        }
    }, [props.vehicleImgs?.length]);

    useEffect(() => {
        if (props.vehicleImgs.data.images?.length > 0) {
            setVehicleImgs(props?.vehicleImgs?.data?.images)
        }
    }, [props.vehicleImgs]);

    useEffect(() => {
        if (props.sharedReply) {
            setNoteOpen(true)
        }
    }, [props.sharedReply])

    useEffect(() => {
        let selectedFilesKey = Object.keys(selectedFiles).filter(key => selectedFiles[key] == true).map(Number)
        setFiles(vehicleImgs.filter(item => selectedFilesKey.includes(item.id)))

        var count = Object.values(selectedFiles).filter(item =>  item === true);
        setFileCount(count);
    }, [selectedFiles]);

    const onChoosePhotos = (e) => {
        setSelectedFiles({...selectedFiles, [e.target.name]: e.target.checked});
    };

    const onClickFile = (e, key) => {
        if (isDragging) {
            e.preventDefault()
            return
        }
        let onlyImages = props.vehicleImgs['data']['images'];
        setImages(onlyImages)
        setImgIndex(key)
    
        if (e.target === e.currentTarget) {
            setImgOpen(true)
        }
    }

    const sendFiles = () => {
        let filteredFiles = Object.keys(selectedFiles).filter(key => selectedFiles[key] == true)
        
        if (filteredFiles.length > 0) {
            const token = localStorage.getItem('h-access_token')
            
            post('/jobs/sendPhotos', {data: filteredFiles, jobId: props.item.id}, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                if (result.data.message == 'success') {
                    notification.open({
                        message: 'Alert!',
                        description: "Sent selected files successfully!",
                        onClick: () => {
                        },
                    });

                    setSelectedFiles({})
                } else {
                    notification.open({
                        message: 'Alert!',
                        description: "Failed sending of files",
                        onClick: () => {
                        },
                    });
                }
            }).catch(e => {
                return e
            })
        }
    }

    const downloadPhotos = () => {
        let filteredFiles = Object.keys(selectedFiles).filter(key => selectedFiles[key] === true);
        setDownloadSpin(true)
        if (filteredFiles.length > 0) {
            if (filteredFiles.length === 1) {
                // For a single file, generate a direct download link
                const fileUrl = getFileUrl(filteredFiles[0]); // Retrieve the URL for the single selected file

                fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var link = document.createElement('a');
                    link.href = url;
                    link.download = getFileName(fileUrl);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);  // Clean up the URL object

                    setDownloadSpin(false)
                    setSelectedFiles({});
                    setFileCount(0)
                })
                .catch(e => {
                    setDownloadSpin(false);
                    setSelectedFiles({});
                    setFileCount(0)
                });
            
            } else {
                // For multiple files, send a request to the API
                const token = localStorage.getItem('h-access_token');
                
                post('/jobs/downloadPhotos', {data: filteredFiles, jobId: props.item.id}, {
                    "Authorization": `Bearer ` + token
                }).then(result => {
                    if (result?.data?.url) {
                        // Create a download link for the zip file received from the API
                        var link = document.createElement('a');
                        link.href = result?.data?.url;
                        link.download = `file-${moment().format('YMMDDHHmm')}.zip`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        setDownloadSpin(false);
                        setSelectedFiles({});
                        setFileCount(0)
                    }
                }).catch(e => {
                    setDownloadSpin(false);
                    setSelectedFiles({});
                    setFileCount(0)
                });
            }
        }
    };

    const delPhotos = () => {
        let filteredFiles = Object.keys(selectedFiles).filter(key => selectedFiles[key] === true);
        const token = localStorage.getItem('h-access_token');
        if(filteredFiles.length && window.confirm(`Are you sure you wish to delete ${filteredFiles.length} images?`)) {
            setDownloadSpin(true)
            
            post('/jobs/deletePhotos', {jobImageId: filteredFiles, jobId: props.item.id}, {
                "Authorization": `Bearer ` + token
            }).then(result => {
                if(result.status == 200 && result.data.message == "Files deleted.") {
                    setDownloadSpin(false)
                    let imgs = vehicleImgs.filter(item => !filteredFiles.includes(String(item.id)))
                    setVehicleImgs(imgs);
                    setFileCount(0)
                    setSelectedFiles({})
                }
            }).catch(e => {
                notification.open({
                    message: 'Alert!',
                    description: "Failed deleting of files",
                    onClick: () => {
                    },
                });
            });
        }
    }

    // Utility function to extract the file name from a URL
    function getFileName(url) {
        return url.substring(url.lastIndexOf('/') + 1);
    }

    function getFileUrl(fileId) {
        const file = vehicleImgs.find(item => item.id == fileId);
        return file ? file.media_path : null;
    }
    
 

    const onRemoveFile = (id) => {
        setSelectedFiles({...selectedFiles, [id]: false});
    }

     const onNoteModal = (data) => {
        if(data == undefined) {
            setBackUpData({})
        } else {
            setBackUpData(data)
        }
        
        setNoteOpen(false)
     }

    return (
        <div className="photomanagement d-flex flex-column justify-content-between h-full">
            <Spin spinning={downloadSpin}>
                <Block
                    title='Files'
                    isOpen={filesOpen}
                    onToggle={() => {
                        let filesOpenN = !filesOpen
                        setFilesOpen(filesOpenN)
                    }}
                >
                    <div className='photomanagement-photos'>

                        <div className='add_icon mb-2'>
                            <span>Select files if you want to attach them to a note</span>
                        </div>
                        <div className="custom-slider space">
                            {props.vehicleImgs.loading ?
                                <Skeleton active />
                                :
                                <Slider {...settings}>
                                    <div className="slider-item" onClick={() => setUploaderOpen(!uploaderOpen)} >
                                        <img
                                            className='add-img'
                                            alt=""
                                            src={require("../../assets/images/production_board/add_photo.png").default}
                                        />
                                    </div>
                                    {
                                        Object.keys(props.vehicleImgs.data).length > 0 && vehicleImgs.length > 0 &&
                                        vehicleImgs.map((item, key) => {
                                            return (
                                                <div key={key} className="slider-item">
                                                    {
                                                        item.media_type != 'pdf' ?
                                                            <>
                                                                <img src={item.media_path} />
                                                                <div className={(selectedFiles[item.id] ? 'no-background' : '') + ' check-wrap p-1'} onClick={(e) => onClickFile(e, key)}>
                                                                    <input className="form-check-input d-none" checked={selectedFiles[item.id]} type="checkbox" name={item.id} id={`photo-check-` + key} onChange={event => onChoosePhotos(event)} />
                                                                    <label htmlFor={`photo-check-` + key} className={fileCount.length > 0 ? 'full-container' : ''}>
                                                                        <svg width="20px" height="20px" className="check-back" viewBox="0 0 24 24"><circle opacity=".26" fill="url(#checkbox-shadow-circle)" cx="12" cy="13.512" r="10.488"></circle><circle className="zlMbnc" cx="12" cy="12.2" r="8.292"></circle></svg>
                                                                        <svg width="20px" height="20px" className="check-prev" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
                                                                    </label>
                                                                    <svg onClick={(e) => onClickFile(e, key)} width="24px" height="24px" className={(fileCount.length > 0 ? '' : 'd-none') + ' zoom-icon'} jsaction="click:eQuaEb;focus:AHmuwe; blur:O22p3e" aria-label="Open" viewBox="0 0 24 24" title="Open" role="button" tabIndex="0" jslog="38279; track:click"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm1-7.5h-2v2h-2v2h2v2h2v-2h2v-2h-2z"></path></svg>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div>
                                                                    <a className='d-table flex-column pdf-icon-wrap' target='_blank' href={item.media_path} rel="noreferrer">
                                                                        <img src={require('../../assets/images/pdf-icon.png').default} />
                                                                        <p>{item.media_name}</p>
                                                                    </a>
                                                                </div>
                                                                <div className={(selectedFiles[item.id] ? 'no-background' : '') + ' check-wrap p-1'} onClick={(e) => onClickFile(e, key)}>
                                                                    <input className="form-check-input d-none" checked={selectedFiles[item.id]} type="checkbox" name={item.id} id={`photo-check-` + key} onChange={event => onChoosePhotos(event)} />
                                                                    <label htmlFor={`photo-check-` + key} className={fileCount.length > 0 ? 'full-container' : ''}>
                                                                        <svg width="20px" height="20px" className="check-back" viewBox="0 0 24 24"><circle opacity=".26" fill="url(#checkbox-shadow-circle)" cx="12" cy="13.512" r="10.488"></circle><circle className="zlMbnc" cx="12" cy="12.2" r="8.292"></circle></svg>
                                                                        <svg width="20px" height="20px" className="check-prev" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>
                                                                    </label>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            }

                        </div>
                    </div>
                    <div className="files-footer">
                        {fileCount.length > 0 &&
                            <span className='drag_drop_dark_text selected mr-2 sf-pro-light'>
                                Selected {fileCount.length} items
                                <svg className='ml-2' onClick={() => downloadPhotos()} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M8 12.175 3.175 7.35 4.25 6.275l3 3V0h1.5v9.275l3-3 1.075 1.075L8 12.175ZM1.5 16c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-3.575h1.5V14.5h13v-3.575H16V14.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-13Z" fill="#5D6F88" /></svg>
                                <img className='ml-2 pointer' onClick={() => delPhotos()} alt="" src={require("../../assets/images/trash.svg").default} />
                            </span>
                        }

                        <div className='file-btns'>
                            <FilledBrightButton
                                title="+  Add to Note"
                                clickEvent={(e) => setNoteOpen(!noteOpen)}
                                className="justify-content-center photomanagement-button-add ml-auto"
                            />
                            <FilledLightButton
                                title="Clear"
                                clickEvent={(e) => { setSelectedFiles({}) }}
                                className="justify-content-center my-3 my-md-0 photomanagement-button-clear"
                            />
                        </div>
                    </div>
                </Block>
                {
                    uploaderOpen &&
                    <Modal
                        className="photomanagement_modal"
                        open={uploaderOpen}
                        footer={null}
                        width={492}
                        onOk={() => setUploaderOpen(false)}
                        onCancel={() => setUploaderOpen(false)}
                        closeIcon={
                            <img
                                className="modal-close"
                                alt=""
                                src={require("../../assets/images/production_board/modal-close.svg").default}
                            />
                        }
                        centered
                    >
                        <VehicleUploader jobId={props.item.id} open={uploaderOpen} />
                    </Modal>
                }
                {imgOpen && (
                    <Lightbox
                        mainSrc={images[imgIndex]['media_path']}
                        nextSrc={images[(imgIndex + 1) % images.length]['media_path']}
                        prevSrc={images[(imgIndex + images.length - 1) % images.length]['media_path']}
                        onCloseRequest={() => setImgOpen(false)}
                        onMovePrevRequest={() =>
                            setImgIndex((imgIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setImgIndex((imgIndex + 1) % images.length)
                        }
                        imageLoadErrorMessage={
                            <di>{images[imgIndex]['media_type'] === 'pdf' ?
                                <iframe className='lightbox-iframe' src={images[imgIndex]['media_path']} ></iframe>
                                : 'This image failed to load'}
                            </di>
                        }
                        enableZoom={images[imgIndex]['media_type'] === 'pdf' ? false : true}
                    />
                )}
                {
                    noteOpen &&
                    <Modal
                        className="note-modal"
                        open={noteOpen}
                        footer={null}
                        width={492}
                        onOk={() => { setBackUpData({}); setNoteOpen(false); dispatch({ type: 'REPLY_NOTE', payload: false }) }}
                        onCancel={() => { setBackUpData({}); setNoteOpen(false); dispatch({ type: 'REPLY_NOTE', payload: false }) }}
                        closeIcon={
                            <img
                                className="modal-close"
                                alt=""
                                src={require("../../assets/images/production_board/modal-close.svg").default}
                            />
                        }
                        centered
                    >
                        <AddNodes sharedReply={props.sharedReply} currentNoteIndex={props.currentNoteIndex} currentNote={props.currentNote} currentUser={props.currentUser} backUpData={backUpData} jobItem={props.item} jobId={props.item.id} files={files} setNoteOpen={(data) => onNoteModal(data)} removeFile={(id) => onRemoveFile(id)} />
                    </Modal>
                }
            </Spin>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        vehicleImgs: state.JobReducer.vehicleImgs,
        colorList: state.JobReducer.colorList,
        sharedReply: state.NoteReducer.sharedReply,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJobVehicleImgs: (id) => dispatch(getJobVehicleImgs(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoManagement);