import {
    GET_VEHICLE_MAKES,
    CREATE_NEW_JOB,
    UPDATE_JOB,
    INITIAL_STATES,
    GET_JOBS,
    GET_CHOOSE_JOB,
    DEL_JOB,
    GET_CUSTOMERS,
    CREATE_CUSTOMER,
    GET_JOB_STATUSES,
    GET_JOB_BY_ORG,
    GET_ASSIGNED_USERS,
    GET_JOB_COLORS,
    UPDATE_JOB_STATUS,
    UPLOAD_JOB_VEHICLE_IMG,
    GET_PLANNING_JOBS,
    GET_JOB_VEHICLE_IMG,
    GET_ASSIGNABLE_USERS,
    SCHEDULE_PLANNING_JOBS,
    SEARCHKEY,
    GET_JOB_FILTERS
} from "./actionTypes"

export const getVehicleMakes = () => {
    return {
        type: GET_VEHICLE_MAKES
    }
}

export const getVehicleMakesSuccess = (payload) => {
    return {
        type: GET_VEHICLE_MAKES + `_SUCCESS`,
        payload: payload
    }
}

export const getFailed = (payload) => {
    return {
        type: GET_VEHICLE_MAKES + `_FAILED`,
        payload: payload
    }
}

export const createNewJob = (payload, flag) => {
    return {
        type: flag ? UPDATE_JOB : CREATE_NEW_JOB,
        payload: payload
    }
}

export const createNewJobSuccess = (payload, flag) => {
    return {
        type: flag ? UPDATE_JOB + `_SUCCESS` : CREATE_NEW_JOB + `_SUCCESS`,
        payload: payload
    }
}

export const createNewJobFailed = (payload, flag) => {
    return {
        type: flag ? UPDATE_JOB + `_FAILED` : CREATE_NEW_JOB + `_FAILED`,
        payload: payload
    }
}

export const searchKeys = (key) => {
    return {
        type: SEARCHKEY,
        key: key
    }
}

export const getJobs = (offset) => {
    return {
        type: GET_JOBS,
        offset: offset
    }
}

export const getJobsSuccess = (payload) => {
    return {
        type: GET_JOBS + `_SUCCESS`,
        payload: payload
    }
}

export const getJobsFailed = (payload) => {
    return {
        type: GET_JOBS + `_FAILED`,
        payload: payload
    }
}

export const getPlanningJobs = (dayDuration, isLoading) => {
    return {
        type: GET_PLANNING_JOBS,
        dayDuration: dayDuration,
        isLoading: isLoading
    }
}

export const getPlanningJobsSuccess = (payload) => {
    return {
        type: GET_PLANNING_JOBS + `_SUCCESS`,
        payload: payload
    }
}

export const getPlanningJobsFailed = (payload) => {
    return {
        type: GET_PLANNING_JOBS + `_FAILED`,
        payload: payload
    }
}

export const scheduleJobs = (payload) => {
    return {
        type: SCHEDULE_PLANNING_JOBS,
        payload: payload
    }
}

export const scheduleJobsSuccess = (payload) => {
    return {
        type: SCHEDULE_PLANNING_JOBS + `_SUCCESS`,
        payload: payload
    }
}

export const scheduleJobsFailed = (payload) => {
    return {
        type: SCHEDULE_PLANNING_JOBS + `_FAILED`,
        payload: payload
    }
}

export const getChooseJob = (id, loading) => {
    return {
        type: GET_CHOOSE_JOB,
        id: id,
        loading: loading
    }
}

export const getChooseJobSuccess = (payload) => {
    return {
        type: GET_CHOOSE_JOB + `_SUCCESS`,
        payload: payload
    }
}

export const getChooseJobFailed = (payload) => {
    return {
        type: GET_CHOOSE_JOB + `_FAILED`,
        payload: payload
    }
}

export const deleteJob = (id) => {
    return {
        type: DEL_JOB,
        id: id
    }
}

export const delJobSuccess = (id) => {
    return {
        type: DEL_JOB + `_SUCCESS`,
        id: id
    }
}

export const delJobFailed = (id) => {
    return {
        type: DEL_JOB + `_FAILED`,
        id: id
    }
}

export const getCustomers = () => {
    return {
        type: GET_CUSTOMERS,
        payload: {
            typeId: 4,
            active: 1
        }
    }
}

export const getCustomersSuccess = (payload) => {
    return {
        type: GET_CUSTOMERS + `_SUCCESS`,
        payload: payload
    }
}

export const getCustomersFailed = () => {
    return {
        type: GET_CUSTOMERS + `_FAILED`
    }
}

export const createCustomer = (payload) => {
    return {
        type: CREATE_CUSTOMER,
        payload: payload
    }
}

export const createCustomerSuccess = (payload) => {
    return {
        type: CREATE_CUSTOMER + `_SUCCESS`,
        payload: payload
    }
}

export const createCustomerFailed = (payload) => {
    return {
        type: CREATE_CUSTOMER + `_FAILED`,
        payload: payload
    }
}

export const getJobStatues = () => {
    return {
        type: GET_JOB_STATUSES
    }
}

export const getJobByOrg = () => {
    return {
        type: GET_JOB_BY_ORG
    }
}

export const getJobStatuesSuccess = (payload) => {
    return {
        type: GET_JOB_STATUSES + `_SUCCESS`,
        payload: payload
    }
}

export const getJobStatuesFailed = (payload) => {
    return {
        type: GET_JOB_STATUSES + `_FAILED`,
        payload: payload
    }
}

export const getAssignedUsers = () => {
    return {
        type: GET_ASSIGNED_USERS
    }
}

export const getAssignedUsersSuccess = (payload) => {
    return {
        type: GET_ASSIGNED_USERS + `_SUCCESS`,
        payload: payload
    }
}

export const getAssignedUsersFailed = (payload) => {
    return {
        type: GET_ASSIGNED_USERS + `_FAILED`,
        payload: payload
    }
}

export const getColorList = () => {
    return {
        type: GET_JOB_COLORS
    }
}

export const getColorListSuccess = (payload) => {
    return {
        type: GET_JOB_COLORS + `_SUCCESS`,
        payload: payload
    }
}

export const getColorListFailed = (payload) => {
    return {
        type: GET_JOB_COLORS + `_FAILED`,
        payload: payload
    }
}

export const getFilterList = () => {
    return {
        type: GET_JOB_FILTERS
    }
}

export const getFilterListSuccess = (payload) => {
    return {
        type: GET_JOB_FILTERS + `_SUCCESS`,
        payload: payload
    }
}

export const getFilterListFailed = (payload) => {
    return {
        type: GET_JOB_FILTERS + `_FAILED`,
        payload: payload
    }
}

export const getAllAssignableUsers = () => {
    return {
        type: GET_ASSIGNABLE_USERS
    }
}

export const getAllAssignableUsersSuccess = (payload) => {
    return {
        type: GET_ASSIGNABLE_USERS + `_SUCCESS`,
        payload: payload
    }
}

export const getAllAssignableUsersFailed = (payload) => {
    return {
        type: GET_ASSIGNABLE_USERS + `_FAILED`,
        payload: payload
    }
}

export const updateJobStatus = (jobId, statusId, itemOrders) => {
    return {
        type: UPDATE_JOB_STATUS,
        payload: {
            jobId: jobId,
            statusId: statusId,
            itemOrders: itemOrders
        }
    }
}

export const updateJobStatusSuccess = () => {
    return {
        type: UPDATE_JOB_STATUS + `_SUCCESS`
    }
}

export const updateJobStatusFailed = (payload) => {
    return {
        type: UPDATE_JOB_STATUS + `_FAILED`,
        payload: payload
    }
}

export const uploadVehicleImgs = (data) => {
    return {
        type: UPLOAD_JOB_VEHICLE_IMG,
        payload: data
    }
}

export const uploadVehicleImgsSuccess = (data) => {
    return {
        type: UPLOAD_JOB_VEHICLE_IMG + `_SUCCESS`,
        payload: data
    }
}

export const uploadVehicleImgsFailed = (data) => {
    return {
        type: UPLOAD_JOB_VEHICLE_IMG + `_FAILED`,
        payload: data
    }
}

export const getJobVehicleImgs = (id) => {
    return {
        type: GET_JOB_VEHICLE_IMG,
        id: id
    }
}

export const getJobVehicleImgsSuccess = (data) => {
    return {
        type: GET_JOB_VEHICLE_IMG + `_SUCCESS`,
        payload: data
    }
}

export const getJobVehicleImgsFailed = (data) => {
    return {
        type: GET_JOB_VEHICLE_IMG + `_FAILED`,
        payload: data
    }
}

export const initVehicle = () => {
    return {
        type: 'INTIAL_VEHICLE'
    }
}

export const initial = () => {
    return {
        type: INITIAL_STATES,
    }
}