import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { createNewJob } from '../store/job/actions';

function Dots(props) {
    const { defaultValue = ['0', '0', '0', '0', '0', '0'], jobData = null, isFilter = false, className='' } = props
    const dotRefs = React.useRef([]);
    const [dotsValue, setDotesValue] = useState(['0', '0', '0', '0', '0', '0'])
    const [updateData, setUpdate] = useState(false)

    useEffect(() => {
        setDotesValue(defaultValue)
    }, [jobData])

    // Function to set focus to the next element on tab press
    const handleKeyDown = (event, index) => {
        if (event.code === 'Tab') {
            event.preventDefault();
            const nextIndex = (index + 1) % dotRefs.current.length;
            dotRefs.current[nextIndex]?.focus();
        } else if (event.code === 'Space') {
            handleDotClick(index)
        }
    }

    const handleDotClick = async (index) => {
        dotsValue[index] = dotsValue[index] === '0' ? '1' : '0'
        await setDotesValue(dotsValue)
        await setUpdate(!updateData)
        dotRefs.current[index]?.focus();
        if (!isFilter)
            props.createNewJob({ ...jobData, dots_bits: dotsValue.join("") }, 1)
    }

    return (
        <div className={`d-flex flex-row gap-2 ${className}`} onClick={(e) => e.stopPropagation()} key={updateData}>
            {dot_colors.map((color, index) => (
                <div key={`${index}_${dotsValue[index]}`} className='dot' style={{ border: `1px solid ${color.color}` }} title={color.title}
                    ref={(el) => (dotRefs.current[index] = el)}
                    tabIndex={-1}
                    onKeyDown={(event) => handleKeyDown(event, index)}
                    onClick={(e) => {
                        handleDotClick(index)

                    }} >
                    <div className='dot-inner' style={{ backgroundColor: dotsValue[index] === '1' ? color.color : '' }} />
                </div>
            ))}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        createNewJob: (data, flag) => dispatch(createNewJob(data, flag))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dots);

const dot_colors = [
    { id: 1, color: "#ff0000", title: "Red dot" },
    { id: 2, color: "#FFCA0D", title: "Yellow dot" },
    { id: 3, color: "#008FD5", title: "Blue dot" },
    { id: 4, color: "#008000", title: "Green dot" },
    { id: 5, color: '#515151', title: "Gray dot" },
    { id: 6, color: '#800040', title: "Purple dot" }
]