import React, { useEffect, useState, forwardRef } from 'react';
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { Select, Modal, Tooltip, Skeleton, notification } from 'antd';
import DatePicker from 'react-datepicker'
import { lightOrDark } from '../../helpers/globals';
import { dateFormat, formatHours, formatNumber } from '../../helpers/stringFormat';
import { grayColor } from '../../helpers/globals';
import { saveCheck } from '../../fakeData/svgFiles';
import {
    getAllAssignableUsers,
    createNewJob,
    getJobByOrg
} from '../../store/job/actions';
import { getNoteList } from '../../store/note/actions';
import AddNodes from '../modals/AddNodes';
import { FilledLightButton } from '@oneblinktech-org/helios-base';
import { createApiClient } from '@oneblinktech-org/helios-base';
import PhotoManagement from './photoManagement';
import Dots from '../Dots';
import Editable from '../Editable';
import Block from '../common/block';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post } = createApiClient({ apiUrl });

const JobDetail = (props) => {
    const history = useHistory();
    const [isDark, setIsDark] = useState("dark");
    const jobStatuses = props.jobStatuses.data;
    const assignableUsers = props.assignableUsers.data;
    const [jobItem, setJobItem] = useState('');
    const [changed, setChanged] = useState(false)
    const [noteOpen, setNoteOpen] = useState(false);
    const [replyNoteOpen, setReplyNoteOpen] = useState(false);
    const [singleNote, setSingleNote] = useState({})
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0)
    const [focusField, setFocusField] = useState('')
    const Option = Select.Option;
    const [years, setYears] = useState([])
    const [activityOpen, setACtivityOpen] = useState(localStorage.getItem('activityOpen') ?? false)
    const [jobValue, setJobValue] = useState('')
    const [jobInDate, setJobInDate] = useState(props?.item?.in_date)
    const [jobOutDate, setJobOutDate] = useState(props?.item?.out_date)

    useEffect(() => {
        const years = range((new Date()).getFullYear(), 1970, -1)
        setYears(years)
    }, [])

    useEffect(() => {
        setJobItem(props.item)
        props.getAllAssignableUsers()
        if (props.item.id != "" && !props.isCardChanged) {
            props.getNoteList(props.item.id)
        }
    }, [props.item]);

    useEffect(() => {
        if (changed) {
            setTimeout(() => {
                props.createNewJob(jobItem, 1)
                setChanged(false)
            }, 500)
        }
    }, [changed]);


    const directEditJob = () => {
        setTimeout(function () {
            history.push(`/job/edit/` + props.item.id)
        }, 1000)
    }

    const onOpenNote = (note, index) => {
        setNoteOpen(true)
        setSingleNote(note)
        setCurrentNoteIndex(index)
        if (!note.read_at) {
            try {
                const token = localStorage.getItem('h-access_token')

                get(`/notes/${note.id}/mark_read`, {
                    "Authorization": `Bearer ` + token
                }).then(result => {
                    if (result?.success == 'Mark as read') {
                        let changedNoteIndex = props.notes.findIndex(data => data.id == note.id);
                        if(changedNoteIndex !== -1) {
                            props.notes[changedNoteIndex].read_at = true
                        }

                        props.getJobByOrg()
                    }
                }).catch(e => {
                    return e
                })
            } catch (error) {
                console.error("Error sending data:", error);
            }
        }
    }

    const onMoveNote = (nextIndex) => {
        setCurrentNoteIndex(nextIndex)
        if (props?.notes[nextIndex]) {
            setSingleNote(props?.notes[nextIndex])
        }
    }

    const focusOutOnEnter = (key, value, field) => {
        const regx = /^[A-HJ-NPR-Z0-9]{17}$/g
        if (!regx.test(value) && field === 'vehicle_vin' && (key === 'Enter' || key === 'Tab')) {
            notification.open({
                type: 'warning',
                message: 'Alert!',
                description: 'Please enter valid VIN number',
                onClick: () => {
                },
            });
            return;
        }
        if (key === 'Enter' || key === 'Tab') {
            setJobItem({ ...jobItem, [field]: field === 'value' && value === '' ? jobValue : value })
            setChanged(true)
        }
        if (key === 'Enter')
            setFocusField('')
    }

    const CustomInput = forwardRef(
        ({ value, onClick, label }, ref) => (
            <div onClick={onClick} ref={ref} className='item d-flex align-items-center justify-content-end gap-1' >
                <span className='item_label'>{label}:</span>
                <span className='item_date'>{value ? dateFormat(value, 'INOUT_DATE') : 'Not set'}</span>
            </div>
        ),
    );

    const changeInOutDate = (date, type) => {
        switch(type) {
            case "IN":
                setJobInDate(date);
                break;
                case "OUT":
                setJobOutDate(date);
                break;
        }

        const token = localStorage.getItem('h-access_token')
    
        post('/jobs/updateDate/' + props.item.id, {date: date, type: type}, {
            "Authorization": `Bearer ` + token
        }).then(result => {

        }).catch(e => {
            notification.open({
                type: 'warning',
                message: 'Alert!',
                description: 'Failed to set, please try again later;',
                onClick: () => {
                },
            });
        })
    }

    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

    return (
        <>
            <div className='expandcard-header'>
                <div className="edit-number" onClick={() => setFocusField('ro_number')} onBlur={() => setFocusField('')}>
                    <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                    <div className='card-id'>
                        <span>#</span>
                        <Editable
                            type='text'
                            defaultValue={props?.item?.ro_number}
                            onChange={(e) => {
                                setJobItem({ ...jobItem, ro_number: e.target.value })
                                setChanged(true)
                            }}
                            focusField={focusField === 'ro_number'}
                            autoFocus={true}
                            tabIndex={1}
                        />
                    </div>
                </div>
                <div className='in-out_dates d-flex align-items-center justify-content-between gap-1'>
                    <DatePicker
                        selected={jobInDate ? new Date(jobInDate) : null}
                        minDate={new Date(1906, 12, 31)}
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
                        onChange={(date) => changeInOutDate(date, "IN")}
                        dateFormat="MMM d"
                        customInput={<CustomInput className="custom-input" label="IN" />}
                        selectsStart
                        startDate={new Date(jobInDate)}
                        endDate={new Date(jobOutDate)}
                        withPortal
                    />
                    <DatePicker
                        selected={jobOutDate ? new Date(jobOutDate) : null}
                        minDate={new Date(jobInDate)}
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 2))}
                        onChange={(date) => changeInOutDate(date, "OUT")}
                        dateFormat="MMM d"
                        customInput={<CustomInput className="custom-input" label="OUT" />}
                        selectsEnd
                        startDate={new Date(jobInDate)}
                        endDate={new Date(jobOutDate)}
                        withPortal
                    />
                </div>
            </div>
            <div className='section' >
                <div className="d-flex flex-column jobdetail justify-content-between">
                    <div className='customer-details'>
                        <div className="info-row">
                            <b className='sf-pro-bold'>{props.item.customer ? `${props.item.customer.lastname} ${props.item.customer.firstname}` : ''}</b>
                            <div className="edit-info d-flex flex-row">
                                <span className='d-sm-none'>{props?.item?.customer?.address ?? '-'}</span>
                                {props?.item?.customer?.main_phone && <img className='phone-svg d-flex' alt="" src={require('../../assets/images/phone.svg').default} />}
                                <span className='border-0'>{props?.item?.customer?.main_phone ?? ''}</span>
                            </div>
                        </div>
                        <div className="info-row d-none d-sm-flex">
                            <span>Address</span>
                            <div className="edit-info">
                                <span className='border-0'>{props?.item?.customer?.address ?? '-'}</span>
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label' >Year / Make / Model</span>
                            <div className="edit-info" >
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <div className='edit-content'>
                                    <div onClick={() => setFocusField('vehicle_year')} onFocus={() => setFocusField('vehicle_year')}>
                                        <Editable
                                            type='select'
                                            defaultValue={props?.item?.vehicle?.year}
                                            onChange={(e) => {
                                                setJobItem({ ...jobItem, vehicle_year: e })
                                                setChanged(true)
                                            }}
                                            focusField={focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year'}
                                            options={years.map((i) => { return { value: i, label: i } })}
                                            tabIndex={2}
                                            size={80}
                                        />
                                    </div>
                                    <span>{focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' ? '/' : ''}</span>
                                    <div onClick={() => setFocusField('vehicle_make_id')} onFocus={() => setFocusField('vehicle_make_id')}>
                                        <Editable
                                            type='select'
                                            defaultValue={props?.item?.vehicle?.make?.name}
                                            onChange={(e) => {
                                                setJobItem({ ...jobItem, vehicle_make_id: e })
                                                setChanged(true)
                                            }}
                                            focusField={focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year'}
                                            options={props?.vehicleMakes?.data?.map((i) => { return { value: i.id, label: i.name } })}
                                            tabIndex={3}
                                            size={130}
                                        />
                                    </div>
                                    <span>{focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year' ? '/' : ''}</span>
                                    <div onClick={() => setFocusField('vehicle_model')} onFocus={() => setFocusField('vehicle_model')}>
                                        <Editable
                                            type='text'
                                            defaultValue={props?.item?.vehicle?.model}
                                            focusField={focusField === 'vehicle_model' || focusField === 'vehicle_make_id' || focusField === 'vehicle_year'}
                                            autoFocus={focusField === 'vehicle_model'}
                                            size={60}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_model')}
                                            tabIndex={4}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Insurance Company</span>
                            <div className="edit-info" onClick={() => setFocusField('insurance_company')} onFocus={() => setFocusField('insurance_company')}>
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <Editable
                                    type='text'
                                    defaultValue={props?.item?.insurance_company}
                                    focusField={focusField === 'insurance_company'}
                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'insurance_company')}
                                    autoFocus={true}
                                    tabIndex={5}
                                    size={130}
                                />
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Color / Paint Code</span>
                            <div className="edit-info" >
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <div className='edit-content'>
                                    <div onClick={() => setFocusField('color')} onFocus={() => setFocusField('color')}>
                                        <Editable
                                            type='text'
                                            defaultValue={props?.item?.vehicle?.exterior_color}
                                            focusField={focusField === 'color' || focusField === 'paint_code'}
                                            autoFocus={focusField === 'color'}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_exterior_color')}
                                            tabIndex={6}
                                        />
                                    </div>
                                    <span>{'/'}</span>
                                    <div onClick={() => setFocusField('paint_code')} onFocus={() => setFocusField('paint_code')}>
                                        <Editable
                                            type='text'
                                            defaultValue={props?.item?.vehicle?.paint_code}
                                            focusField={focusField === 'color' || focusField === 'paint_code'}
                                            autoFocus={focusField === 'paint_code'}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_paint_code')}
                                            tabIndex={7}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>License Plate</span>
                            <div className="edit-info" onClick={() => setFocusField('license')} onFocus={() => setFocusField('license')}>
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <Editable
                                    type='text'
                                    defaultValue={props?.item?.vehicle?.license_plate_number}
                                    focusField={focusField === 'license'}
                                    size={90}
                                    maxLength={10}
                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_license_plate_number')}
                                    autoFocus={true}
                                    tabIndex={8}
                                />
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>VIN</span>
                            <div className="edit-info" onClick={() => setFocusField('vin')} onFocus={() => setFocusField('vin')}>
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <Editable
                                    type='text'
                                    defaultValue={props?.item?.vehicle?.vin}
                                    focusField={focusField === 'vin'}
                                    size={240}
                                    maxLength={17}
                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_vin')}
                                    autoFocus={true}
                                    tabIndex={9}
                                />
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Trim Code</span>
                            <div className="edit-info" onClick={() => setFocusField('trim')} onFocus={() => setFocusField('trim')}>
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <Editable
                                    type='text'
                                    defaultValue={props?.item?.vehicle?.trim_code}
                                    focusField={focusField === 'trim'}
                                    onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'vehicle_trim_code')}
                                    autoFocus={true}
                                    tabIndex={10}
                                />
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Frame Hours / Paint Hours</span>
                            <div className="edit-info">
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <div className='edit-content'>
                                    <div onClick={() => setFocusField('frame_hours')} onFocus={() => setFocusField('frame_hours')} >
                                        <Editable
                                            type='text'
                                            defaultValue={props?.item?.frame_hours !== null ? formatHours(props?.item?.frame_hours, '') : ''}
                                            focusField={focusField === 'frame_hours' || focusField === 'paint_hours'}
                                            size={35}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'frame_hours')}
                                            autoFocus={focusField === 'frame_hours'}
                                            tabIndex={11}
                                            postFix='h'
                                        />
                                    </div>
                                    <span>{'/'}</span>
                                    <div onClick={() => setFocusField('paint_hours')} onFocus={() => setFocusField('paint_hours')}>
                                        <Editable
                                            type='text'
                                            defaultValue={props?.item?.paint_hours !== null ? formatHours(props?.item?.paint_hours, '') : ''}
                                            focusField={focusField === 'frame_hours' || focusField === 'paint_hours'}
                                            size={35}
                                            onKeyDown={(e) => focusOutOnEnter(e.code, e.target.value, 'paint_hours')}
                                            autoFocus={focusField === 'paint_hours'}
                                            tabIndex={12}
                                            postFix='h'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="info-row">
                            <span className='info-label'>Job value</span>
                            <div className="edit-info" onClick={() => setFocusField('job_value')} onFocus={() => setFocusField('job_value')}>
                                <img alt="" src={require('../../assets/images/pencil-blue.svg').default} />
                                <Editable
                                    type='value'
                                    defaultValue={props?.item?.value !== null ? formatNumber(props?.item?.value, 2, '') : ''}
                                    focusField={focusField === 'job_value'}
                                    onKeyDown={(e) => focusOutOnEnter(e.code, '', 'value')}
                                    onChange={(e) => setJobValue(e.floatValue)}
                                    autoFocus={true}
                                    tabIndex={13}
                                    preFix='$'
                                    size={'50%'}
                                />
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="note_modal"
                        centered
                        open={noteOpen}
                        onCancel={() => {
                            setNoteOpen(false)
                            props.replyBack(false)
                        }}
                        footer={null}
                        header={null}
                    >
                        <div className="note_wrap">
                            <div className="note_header">
                                <span>FROM</span>
                                <span>METHOD</span>
                                <span>MESSAGE</span>
                                <span>DATE</span>
                            </div>
                            <div className="note_content">
                                <span>{singleNote?.sender?.firstname}</span>
                                <span><img src={require('../../assets/images/sms-down.png').default} /></span>
                                <span>{singleNote?.message}</span>
                                <span>{dateFormat(singleNote?.created_at, 'TXT_YEAR_DATE')}</span>
                            </div>
                            <div className="note_footer">
                                <div>
                                    <div onClick={() => onMoveNote(currentNoteIndex == (props?.notes?.length - 1) ? currentNoteIndex : currentNoteIndex + 1)} className="note_previus">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M13.178 6.278H2.846L5.95 9.172a.752.752 0 0 1 .245.55.752.752 0 0 1-.245.55.865.865 0 0 1-.59.228.865.865 0 0 1-.589-.228L.244 6.05a.742.742 0 0 1 0-1.1L4.77.728A.862.862 0 0 1 5.36.5c.214 0 .427.076.59.228a.752.752 0 0 1 .244.55.752.752 0 0 1-.244.55L2.846 4.722h10.332c.456.007.822.353.822.778 0 .425-.366.772-.822.778Z" fill={currentNoteIndex < (props?.notes?.length - 1) ? "#0051BA" : "#EAEAEA"} fillOpacity=".7" /></svg>
                                        <span className={currentNoteIndex < (props?.notes?.length - 1) ? "" : "disabled"}>Previous</span>
                                    </div>
                                    <div className="note_reply" onClick={() => props.replyBack(true, currentNoteIndex, props?.notes[currentNoteIndex])}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path d="M12.765 10.5V7.84c0-.683-.262-1.265-.784-1.748-.523-.482-1.154-.724-1.893-.724H2.366l3.17 2.927-.88.801L0 4.798 4.656.5l.88.801-3.17 2.927h7.722c1.081 0 2.004.352 2.767 1.057C13.618 5.99 14 6.841 14 7.84v2.66h-1.235Z" fill="#5D6F88" /></svg>
                                        <span>Reply</span>
                                    </div>
                                </div>
                                <div>
                                    <div onClick={() => onMoveNote(currentNoteIndex == 0 ? 0 : currentNoteIndex - 1)} className="note_next-message">
                                        <span className={currentNoteIndex == 0 ? "disabled" : ""}>Next message</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M.821 6.278h10.333L8.05 9.172a.752.752 0 0 0-.245.55.75.75 0 0 0 .245.55.865.865 0 0 0 .59.228.865.865 0 0 0 .589-.228l4.526-4.222a.742.742 0 0 0 0-1.1L9.23.728A.862.862 0 0 0 8.64.5a.861.861 0 0 0-.59.228.752.752 0 0 0-.244.55c0 .206.088.404.244.55l3.104 2.894H.82C.366 4.73 0 5.075 0 5.5c0 .425.366.772.821.778Z" fill={currentNoteIndex == 0 ? "#EAEAEA" : "#0051BA"} fillOpacity=".7" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {replyNoteOpen &&
                        <Modal
                            className="note-modal"
                            open={replyNoteOpen}
                            footer={null}
                            width={492}
                            onOk={() => { }}
                            onCancel={() => { }}
                            closeIcon={
                                <img
                                    className="modal-close"
                                    alt=""
                                    src={require("../../assets/images/production_board/modal-close.svg").default}
                                />
                            }
                            centered
                        >
                            <AddNodes currentUser={props.currentUser} jobItem={props.item} jobId={props.item.id} />
                        </Modal>
                    }
                </div>
                <PhotoManagement currentNoteIndex={props.currentNoteIndex} currentNote={props.currentNote} currentUser={props.currentUser} item={props.item} />
                <div className='section-action'>
                    <div className="section-item">
                        <label>Status:</label>
                        <Select
                            className='item'
                            tabIndex={14}
                            size='large'
                            placeholder="Select status"
                            value={jobItem?.status_id || undefined}
                            onChange={(item) => {
                                setJobItem({ ...jobItem, status_id: item })
                                setChanged(true)
                            }}
                        >
                            {Object.keys(props.jobStatuses.data).map((key, index) => {
                                return (
                                    <Option key={index} value={parseInt(jobStatuses[key].id)}>
                                        {jobStatuses[key].name}
                                    </Option>
                                )
                            })
                            }
                        </Select>
                    </div>

                    <div className="section-item">
                        <label>Assigned to:</label>
                        <Select
                            tabIndex={15}
                            size='large'
                            placeholder="Select user"
                            value={jobItem.assigned_to_user_id || undefined}
                            onChange={(item) => {
                                setJobItem({ ...jobItem, assigned_to_user_id: item })
                                setChanged(true)
                            }}
                            className="assign-user-select item"
                        >
                            {Object.keys(props.assignableUsers.data).map((key, index) => {
                                return (
                                    <Option key={index} value={assignableUsers[key].id}>
                                        {assignableUsers[key].firstname} {assignableUsers[key].lastname}
                                    </Option>
                                )
                            })
                            }
                        </Select>
                    </div>
                    <div className="section-item">
                        <label>Card color:</label>
                        <div className='d-flex flex-row gap-2 justify-content-between item'>
                            {props.colorList.data.map((item, index) => {
                                return (
                                    <div key={index} className='colour-card' style={{ border: `4px solid ${props.item?.color_id && props.item?.color_id === item.id ? props.colorList.data.find(data => { return data.id == props.item.color_id })['color'] : '#fff'}` }}
                                        onClick={() => {
                                            props.changeBackground(item)
                                            setJobItem({ ...jobItem, color_id: item.id })
                                            setChanged(true)
                                        }}>
                                        <div className='card-left' style={{ backgroundColor: item?.color }} />
                                        <div className='linegroup'>
                                            <div className='rectangleParent'>
                                                <div className='frameChild' />
                                                <div className='frameChild' />
                                            </div>
                                            <div className='rectangleParent'>
                                                <div className='frameInner' />
                                                <div className='frameChild' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>

                    <div className="section-item">
                        <label>dots:</label>
                        <Dots className="item" defaultValue={props?.item?.dots_bits ? Array.from(props.item.dots_bits) : []} jobData={jobItem} isFilter={false} />
                    </div>
                </div>
                <div className='activity-section'>
                    <Block
                        title='Activity'
                        isOpen={activityOpen}
                        onToggle={() => {
                            let activityOpenN = !activityOpen
                            localStorage.setItem('activityOpen', activityOpenN)
                            setACtivityOpen(activityOpenN)
                        }}
                    >
                        <div className='note-placeholder mt-1 w-100'>
                            {props.notes.loading ?
                                <Skeleton active />
                                :
                                <table>
                                    <tbody>
                                        {props.notes.length > 0 &&
                                            props.notes.map((note, index) => (
                                                <tr
                                                    className={`${!note.read_at ? 'is-read' : ''}`}
                                                    key={index}
                                                    onClick={() => onOpenNote(note, index)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <td>{dateFormat(note.created_at, 'TXT_DATE')}</td>
                                                    <td><img src={require('../../assets/images/sms-down.png').default} /></td>
                                                    <td>{note.sender.firstname}</td>
                                                    <td>
                                                        <Tooltip placement="topLeft" color="white" colorText="rgba(0, 0, 0, 0.88)" title={<div className='custom-notification'>{note.message}</div>}>
                                                            <span className='anti-tooltip-open'>{note.message.length > 30 ? (note.message.substring(0, 30) + '...') : note.message}</span>
                                                        </Tooltip>
                                                    </td>
                                                </tr>)
                                            )
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </Block>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        jobStatuses: state.JobReducer.jobStatuses,
        colorList: state.JobReducer.colorList,
        assignableUsers: state.JobReducer.assignableUsers,
        notes: state.NoteReducer.notes?.data,
        vehicleMakes: state.JobReducer.vehicleMakes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJobByOrg: () => dispatch(getJobByOrg()),
        getAllAssignableUsers: () => dispatch(getAllAssignableUsers()),
        createNewJob: (data, flag) => dispatch(createNewJob(data, flag)),
        getNoteList: (jobId) => dispatch(getNoteList(jobId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);