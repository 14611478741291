import React from 'react'
import { useState } from 'react';

import Collapse from '@kunukn/react-collapse'
import { Divider } from 'antd'
import cx from 'classnames'

const Block = (props) => {

    const [isRotated, setIsRotated] = useState(false);

    const handleToggle = () => {
        // Toggle the state when the button is clicked
        setIsRotated(!isRotated);

        // Call your custom onToggle function from props
        props.onToggle();
    };


    return (
        <div className='block w-100'>
          <div className='block-header cursor-pointer' onClick={handleToggle}>
                <b> {props.title}</b>
                <div className={cx('no-rotated', 'p-0', { 'is-rotated': isRotated })}>
                    <svg
                        width='17'
                        height='11'
                        className={cx('icon-down', { 'is-open': props.isOpen })}
                        viewBox='0 0 17 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path fillRule='evenodd' clipRule='evenodd' d='M0.9375 8.77273L2.39205 10.2273L8.9375 3.68182L15.483 10.2273L16.9375 8.77273L8.9375 0.772727L0.9375 8.77273Z' fill='#3F3B3B' />
                    </svg>
                </div>
          </div>
          <Collapse isOpen={props.isOpen}>
            {props.children}
          </Collapse>
        </div>
    )
}

export default Block