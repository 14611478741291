import React, { useState } from 'react'
import { Select, Tag } from 'antd'
import Dots from '../Dots'

const { CheckableTag } = Tag;

function Filter({ open = false, setFilterOpen = null, colorList = [], setFilterColor = null }) {
    const [filter, setFilter] = useState([]);

    const handleChange = async (color, checked) => {
        const nextSelectedTags = await checked ? [...filter, color] : filter.filter(f_color => f_color !== color);
        setFilter(nextSelectedTags);
    }

    const applyFilter = () => {
        setFilterColor(filter)
        setFilterOpen(false)
    }

    const clearFilter = () => {
        setFilter([])
        setFilterColor([])
        setFilterOpen(false)
    }

    if (open) {
        return (
            <div className={'filter-modal-wrap'} onClick={() => setFilterOpen(false)}>
                <div className='filter-modal' onClick={e => e.stopPropagation()}>
                    <div className='modal-content'>
                        <div className='d-flex flex-wrap gap-3'>
                            <Select
                                className='flex-grow-1'
                                size='large'
                                placeholder='Insurance Company'
                                options={[]}
                            />
                            <Select
                                className='flex-grow-1'
                                size='large'
                                placeholder='Customer'
                                options={[]}
                            />
                            <div className='item-modal flex-grow-1' >
                                <Dots defaultValue={[]} isFilter={true} />
                            </div>
                            <Select
                                className='flex-grow-1'
                                size='large'
                                placeholder='Year'
                                options={[]}
                            />
                            <Select
                                className='flex-grow-1'
                                size='large'
                                placeholder='Make'
                                options={[]}
                            />
                            <Select
                                className='flex-grow-1'
                                size='large'
                                placeholder='Model'
                                options={[]}
                            />
                            <div className='item-modal flex-grow-1'>
                                {colorList.map((color, index) => (
                                    <div key={index} className='color-block' style={{ border: `1px solid ${color.color}` }} >
                                        <div
                                            onClick={() => handleChange(color.color, !filter.includes(color.color))}
                                            style={{ backgroundColor: filter.includes(color.color) ? color.color : '' }}
                                            className="board-color_radio"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='action-btn'>
                        <button className='btn-apply' onClick={() => applyFilter()}>Apply</button>
                        <button className='btn-clear' onClick={() => clearFilter()}>Clear</button>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default Filter