import $ from 'jquery';
$(document).ready(function() {
});

window.onload = function() {

    var $clone = "";
    var $lastelement = "";
    var lastelement = {
        'top': 0,
        'left': 0,
        'width': 0,
        'height': 0
    };
    var cloneflipped = false;
    
    $(document).on("transitionend webkitTransitionEnd oTransitionEnd touchend", '#expandCard', function (e) {
        if (e.target === e.currentTarget || $(e.target).is('img.extend-modal-close.modal-close')) {
            if (e.originalEvent.propertyName == 'top' || e.originalEvent.type == 'touchend') {
                if ($(e.target).is('img.extend-modal-close.modal-close')) {
                    $('.expandCardBack').click();
                }
                cloneflipped = !cloneflipped;
                if (!cloneflipped) {
                    $($lastelement).css({'opacity': 1});
                    $($clone).hide();
                } else {
                }
            }
        }
    });

    $( document ).on( "click", ".drag_drop-card", function() {
        $clone = $('#expandCard');

        if (!cloneflipped) {
            $lastelement = $(this);

            var offset = $lastelement.offset();
            lastelement.top = offset.top - $(document).scrollTop();
            lastelement.left = offset.left;
            lastelement.width = $lastelement.width();
            lastelement.height = $lastelement.height();

            $clone.css({
                'display': 'flex',
                'top': lastelement.top,
                'left': lastelement.left,
                'transform': 'unset',
                'width': '190px',
                'height': '97.5px'
            });
            $lastelement.css('opacity', 0);
            var vpWidth = document.documentElement.clientWidth;
            var vpHeight = document.documentElement.clientHeight;
            $(".card-color-line").show();
            $(".expandcard-header").show();
            $(".expandedcard_modal").css({ 'overflow-y': 'auto' })


            $clone.css({
                'transition': 'all 400ms cubic-bezier(0.16, 1, 0.3, 1)',
                'top': '50%',
                'left': '50%',
                'transform': 'translate(-50%, -50%)',
                'height': 'auto',
                'max-height': '85%',
                'max-width': '1075px',
                'width': vpWidth > 768 ? '70%' : '95%',
                'box-shadow': '0px 2px 41px -4px rgb(40 41 61 / 23%), 0px 4px 10px rgb(34 34 35 / 23%)'
            });
            $('html, body').animate({ scrollTop: 0 }, 100);
            
            $(".expandedcard_content").removeClass("d-none");
            $(".expandedcard_content").css({opacity: 0}).animate({opacity: 1}, 150).css({
                'overflow-y': vpWidth > 768 ? 'hidden' : 'auto',
                'height': 'auto',
                'overflow-x': 'hidden'
            });
            $(".expandCardBack").fadeIn(150);
        } else {
            $('.expandCardBack').click();
        }

    });

    $(document).on('click', '.extend-modal-close', function (e) {
        $('.expandCardBack').click();
    });

    $(document).on('click', '.header-modal-mark', function (e) {
        if(!$('.expandCardBack').hasClass("d-none")) {
            $('.expandCardBack').click();
        }
    })
    
    $(document).on('click', '.expandCardBack', function (e) {
        if (cloneflipped) {
            $(".expandCardBack").fadeOut(150);
            $clone.css({
                'transition': 'all 250ms cubic-bezier(.08,.02,.7,-0.27)',
                'top': lastelement.top + 'px',
                'left': lastelement.left + 'px',
                'height': '97.5px',
                'width': '190px',
                'transform': 'unset',
                'box-shadow': 'unset'
            });
            $(".expandedcard_content").animate({opacity: 0}, 50);
            $(".card-color-line").hide();
            $(".expandedcard_modal").css({ 'overflow-y': 'hidden' })
            $(".expandcard-header").hide()
        }
    });
}