import {
    CREATE_NEW_NOTE,
    GET_NOTES,
    GET_CHOOSE_NOTE,
    UPDATE_NOTE,
    GET_NOTE_LISTS,
    GET_NOTE_STATUSES,
    GET_COUNTER_DATA
} from "./actionTypes";
import { getDaysOfWeek } from '../../helpers/getWeekDays';
import { notification } from 'antd';

const LAST_WEEK = -1;
const FIVE_WEEKS = 35;

const initialState = {
    notes: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    newNote: {
        loading: false,
        errors: {},
        message: "",
        data: {}
    },
    sharedReply: false,
    isAuthenticated: localStorage.getItem('h-access_token') ? true : false,
    countData: {
        planning_count: 0
    }
};

// User type
const SuperAdmin = 1;
const Primary = 2;
const Subordinate = 3;
const Customer = 4;


const NoteNotify = (description) => {
    notification.open({
        message: 'Alert!',
        description: description,
        onClick: () => {
        },
    });
}

const NoteReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_NOTE_LISTS:
        state = {
            ...state,
            notes: {
                ...initialState.notes,
                loading: true
            }
        }
        break;
    case GET_NOTES + `_SUCCESS`:
        state = {
            ...state,
            notes: {
                ...state.notes,
                data: action.payload.notes,
                loading: false
            }
        }
        break;
    case GET_NOTES + `_FAILED`:
        state = {
            ...state,
            notes: {
                ...state.notes,
                error: action.payload,
                loading: false
            } 
        };
        break;
    case 'REPLY_NOTE':
        state = {
            ...state,
            sharedReply: action.payload
        };
        break;
    case GET_CHOOSE_NOTE:
        state = {
            ...state,
            newNote: {
                ...state.newNote,
                loading: true
            }
        }
        break;
    case GET_CHOOSE_NOTE + `_SUCCESS`:
        state = {
            ...state,
            newNote: {
                ...state.newNote,
                data: action.payload,
                loading: false
            }
        }
        break;
    case GET_CHOOSE_NOTE + `_FAILED`:
        state = {
            ...state,
            newNote: {
                ...state.notes,
                error: action.payload,
                loading: false
            } 
        };
        break;
    case CREATE_NEW_NOTE:
        state = { 
            ...state, 
            newNote: {
                ...state.newNote,
                loading: true,
                errors: {},
                message: "",
                data: {}
            },
        };
        break;
    case CREATE_NEW_NOTE + `_SUCCESS`:
        state = { 
            ...state, 
            newNote: {
                ...state.newNote,
                loading: false,
                errors: {},
                message: "Note #" + action.payload.data.id + " created",
            },
        };
        NoteNotify(state.newNote.message);
        break;
    case CREATE_NEW_NOTE + `_FAILED`:
        state = {
            ...state,
            newNote: {
                ...state.newNote,
                newNoteMessage: false,
                error: action.payload,
                loading: false,
            }
        };
        NoteNotify("Failed to create note. Please try again or contact an administrator. Error code 47");
        break;
    case UPDATE_NOTE:
        state = { 
            ...state
        };
        break;
    case UPDATE_NOTE + `_SUCCESS`:
        state = { 
            ...state
        };
        NoteNotify("Updated successfully!");
        break;
    case UPDATE_NOTE + `_FAILED`:
        state = {
            ...state,
            newNote: {
                newNoteMessage: false,
                error: action.payload,
                loading: false,
            }
        };
        NoteNotify("Failed to update note. Please try again or contact an administrator. Error code 48.");
        break;
    case GET_NOTE_STATUSES:
        state = {
            ...state,
            noteStatuses: {
                ...state.noteStatuses,
                loading: true,
                errors: {},
                message: false
            }
        };
        break;
    case `UPDATE_COUNTER_DATA`:
        state = {
            ...state,
            countData: {
                ...state.countData,
                planning_count: action.payload
            }
        }
        break;
    case `INCREASE_COUNTER_DATA`:
        state = {
            ...state,
            countData: {
                ...state.countData,
                planning_count: state?.countData?.planning_count + 1
            }
        }
        break;
    case GET_COUNTER_DATA + '_SUCCESS':
        state = {
            ...state,
            countData: {
                ...state.countData,
                ...action.payload
            }
        }
        break;
    case GET_NOTE_STATUSES + `_SUCCESS`:
        state = {
            ...state,
            noteStatuses: {
                ...state.noteStatuses,
                loading: false,
                message: true,
                errors: {},
                data: {...action.payload}
            }
        };
        break;
    case GET_NOTE_STATUSES + `_FAILED`:
        state = {
            ...state,
            noteStatuses: {
                ...state.noteStatuses,
                loading: false,
                message: false,
                errors: action.payload,
                data: {}
            },
        };
        break;
    default:
        state = { ...state };
        break;
  }
  return state;
};

export default NoteReducer;