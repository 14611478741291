import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-rangeslider/lib/index.css'
import 'antd/dist/antd.css'
import { HeliosWrap, createApiClient } from '@oneblinktech-org/helios-base'
import SearchActions from './components/searchActions';
import { useDispatch, useSelector } from 'react-redux'
import PlanningBoard from "./pages/planningBoard"
import Profile from './pages/user/Profile'
import JobList from './pages/job/JobList'
import Job from './pages/job/Job'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import {
  getJobByOrg
} from './store/job/actions';
import { menus as initialMenus } from './fakeData/sideBar';
import { konamiKeyEvent, removeKonamiKeyEvent } from "./helpers/globals"
import { getCookie } from "./helpers/globals"
import Connections from "./pages/Settings/Connections"
import Pusher from "pusher-js";
import Logo from "./components/common/logo"

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post } = createApiClient({ apiUrl });

function App(props) {
  konamiKeyEvent()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [menus, setMenus] = useState(initialMenus);
  const initialRender = useRef(true);
  const countData = useSelector((state) => state.NoteReducer.countData);
  const user = useSelector((state) => state.UserReducer.user);

  useEffect(() => {
    if(Object.keys(user?.data).length > 0) {
      dispatch({type: "GET_COUNTER_DATA"})

      var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_API_KEY}`, {
        cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
      });

      var orgChannel = pusher.subscribe("organizations");
      orgChannel.bind("choose-event", (data) => {
          dispatch({type: "GET_COUNTER_DATA"})
      });

      return (() => {
        pusher.unsubscribe('organizations')
      })
    }
  }, [user?.data])

  useEffect(() => {
    if(initialRender.current) {
      initialRender.current = false;
    } else {
      updateCounter(countData)
    }
  }, [countData])
  
  const updateCounter = useCallback((result) => {
    const newMenus = initialMenus.map(menu => {
      if (menu.name === 'planning') {
        return {
          ...menu,
          count: result?.planning_count || 0,
        };
      }
      return menu;
    });

    setMenus(newMenus);
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      updateCounter(countData);
    }
  }, [countData, updateCounter]);

  return (
    <div className="App">
      <Switch>
        <Route path={["/jobs", "/job/add", "/job/edit/:id", "/profile", "/connections"]}>
          <HeliosWrap
            captchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            fullWidth={false}
            orgChangeEvent={(orgId) => dispatch(getJobByOrg(orgId))}
            siteLogo={<Logo />}
            siteText="Helios"
            searchComponent={<SearchActions />}
            isWelcome={false}
            leftSidebarItems={menus}
            apiUrl={process.env.REACT_APP_API_URL}
            isNavShow={process.env.REACT_APP_SHOW_NAV_SIDE || true}
          >
            <Switch>
              <Route path="/jobs" render={() => <JobList />} />
              <Route path="/job/add" render={(props) => <Job {...props} />} />
              <Route path="/job/edit/:id" render={(props) => <Job {...props} />} />
              <Route path="/profile" render={() => <Profile />} />
              <Route path="/connections" render={() => <Connections />} />
              {/*<Route path="/" component={Dashboard} />*/}
            </Switch>
          </HeliosWrap>
        </Route>
        <Route path={["/planning", "/production", "/"]}>
          <HeliosWrap
            captchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            googleClientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            fullWidth={true}
            orgChangeEvent={(orgId) => dispatch(getJobByOrg(orgId))}
            leftSidebarItems={menus}
            siteLogo={<Logo />}
            siteText="Helios"
            searchComponent={<SearchActions />}
            isWelcome={false}
            apiUrl={process.env.REACT_APP_API_URL}
            isNavShow={process.env.REACT_APP_SHOW_NAV_SIDE || true}
          >
            <Switch>
              <Route path="/planning" render={() => <PlanningBoard />} />
              <Route path="/production" render={() => <Home />} />
              <Route path="/" render={() => <Home />} />
            </Switch>
          </HeliosWrap>
        </Route>
      </Switch>
    </div>
  );
}

export default App;